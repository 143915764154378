@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
  border-top-color: #3498db;
  -webkit-animation: spiner 1.5s linear infinite;
  animation: spiner 1.5s linear infinite;
}

@-webkit-keyframes spiner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spiner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
